import React from "react";
import Nav from './Nav';
import Valide from '../assets/images/valide.svg';
import { Link } from "react-router-dom";
import MenuHeader from "./MenuHeader";

const ConfirmationPage = () => {
    return (
        <>
            <MenuHeader />
            <div className="content-wrap">
                <div className="list-item-with-image">
                    <div className="title">
                        <img
                            className="https-app"
                            alt="Https app"
                            src={Valide}
                        />

                        <div className="text-wrapper">Merci!</div>

                        <p className="div">
                            Vous avez reçu un email avec votre confirmation et votre reçu.
                        </p>
                    </div>
                </div>

                <p className="merci-de-votre">
                    <span className="span">
                        Merci de votre confiance.
                        <br />
                    </span>

                    <span className="text-wrapper-2">First Love Yourself</span>
                </p>

                <div className="buttons">
                    <button className="button">
                        <Link to="/commande" >
                            <div className="book-a-treatment">Voir mes commandes</div>
                        </Link>
                    </button>
                </div>
            </div>
        </>
    );
};


export default ConfirmationPage;