import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import { endpoints } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import ValidationPage from "./ValidationPage";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const InscriptionForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    civility: "", // Peut être 'MS' ou 'MR'
    birthdate: "", // Format : 'YYYY-MM-DD'
  });
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [submitted, setSubmitted] = useState(false);
  const { id, provider, google_token, email, given_name, family_name, fcm_token } =
    location.state || {};

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const togglePasswordVisibility = () => {
    setFormData((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  useEffect(() => {
    console.log("state", location.state)
    if ((google_token && fcm_token) || (id && fcm_token)) {
      setFormData(prevFormData => ({
        ...prevFormData,
        email: email || prevFormData.email, // Mettre à jour l'email si disponible
        firstname: given_name || prevFormData.firstname, // Mettre à jour le prénom si disponible
        lastname: family_name || prevFormData.lastname, // Mettre à jour le nom de famille si disponible
        google_token: google_token,
        fcm_token: fcm_token,
        is_new: true,
        id: id
      }));
    }
  }, [google_token, fcm_token, email, given_name, family_name, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post(email ? provider == "apple" ? endpoints.appleConnect : endpoints.googleConnect : endpoints.register, formData, {
        headers: { "Content-Type": "application/json" },
      });
      setSubmitted(true);
      navigate("/VerificationNumero");

      // navigate("/validation", { state: { email: formData.email } });
    } catch (error) {

    }
  };

  return (
    <div className="">
      <header>
        <div className="container">
          <a href="#">Back</a>
          <div className="logo">Flyspa</div>
        </div>
      </header>
      <div className="form-inscription">
        <form onSubmit={handleSubmit}>
          <h2>Inscription</h2>
          <div className="form-choix">
            <div className="form-radio">
              <input
                type="radio"
                name="civility"
                value="MS"
                id="MS"
                checked={formData.civility === "MS"}
                onChange={handleChange}
                required
              />
              <label htmlFor="MS">MS</label>
            </div>
            <div className="form-radio">
              <input
                type="radio"
                name="civility"
                value="MR"
                id="MR"
                checked={formData.civility === "MR"}
                onChange={handleChange}
                required
              />
              <label htmlFor="MR">MR</label>
            </div>
          </div>

          <div className="form-text">
            <label>Nom:</label>
            <input
              type="text"
              name="lastname"
              value={family_name ? family_name : formData.lastname}
              readOnly={family_name && true}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-text">
            <label>Prénom:</label>
            <input
              type="text"
              name="firstname"
              value={given_name ? given_name : formData.firstname}
              onChange={handleChange}
              readOnly={given_name && true}
              required
            />
          </div>

          <div className="form-text">
            <label>Numéro de téléphone portable:</label>
            <PhoneInput
              defaultCountry="ch"
              type="tel"
              name="phone"
              value={formData.phone}
              disableCountryGuess="true"
              //hideDropdown="true"
              forceDialCode="true"
              //onChange={handlePhoneChange}
              onChange={(phone) => handlePhoneChange(phone)}
              required
            />
          </div>

          <div className="form-text">
            <label>Adresse email:</label>
            <input
              type="email"
              name="email"
              value={email ? email : formData.email}
              onChange={handleChange}
              readOnly={email && true}
              required
            />
          </div>
          {!email && (
            <div className="form-text">
              <label>Mot de passe:</label>
              <div className="pass-eye">
                <input
                  type={formData.showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={togglePasswordVisibility}>
                  {formData.showPassword ? "Cacher" : "Voir"}
                </button>
              </div>
            </div>
          )}
          <div className="form-text">
            <label>Date de naissance:</label>
            <input
              type="date"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleChange}
            />
          </div>

          <div className="form-checkbox">
            <input
              type="checkbox"
              name="acceptCGU"
              id="acceptCGU"
              checked={formData.acceptCGU}
              onChange={handleChange}
              required
            />
            <label htmlFor="acceptCGU">
              J'accepte les CGU et la Politique de confidentialité
            </label>
          </div>

          <button type="submit" className="btn-submit">
            Créer un compte
          </button>
        </form>
      </div>
    </div>
  );
};

export default InscriptionForm;
