import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Accueil from "./Components/Accueil";
import User from "./Components/User/User";
import "./App.css";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import ChooseLocation from "./Components/ChooseLocation";
import ChooseDuration from "./Components/ChooseDuration";
import ChooseTherapist from "./Components/ChooseTherapist";
import ChooseService from "./Components/ChooseService";
import Payment from "./Components/Payment";
import Confirmation from "./Components/Confirmation";
import Contact from "./Components/Contact";
import VerificationNumero from "./Components/VerificationNumero";
import ValidationNumero from "./Components/ValidationNumero";
import Profile from "./Components/Profile";
import Gift from "./Components/Gift";
import Voucher from "./Components/Voucher";
import Treatment from "./Components/Treatment";
import Package from "./Components/Package";
import FavFlyPro from "./Components/FavFlyPro";
import LocationPro from "./Components/LocationPro";
import FlyProService from "./Components/FlyProService";
import EditProfile from "./Components/EditProfile";
import EditAdresse from "./Components/EditAdresse";
import Register from "./Components/Register";
import ValidationPage from "./Components/ValidationPage";
import { useEffect } from "react";
import { generateToken, messaging } from "./utils/firebase";
import toast, { Toaster } from 'react-hot-toast';
import Commande from "./Components/Commande";
import Hotel from "./Components/Hotels";
import { onMessage } from "@firebase/messaging";

const ROLES = {
  Client: "ROLE_CLIENT",
  Editor: "ROLE_USER",
  Admin: "ROLE_ADMIN",
};

function App() {

  useEffect(() => {
     generateToken();
     console.log(generateToken())
     onMessage(messaging, (payload) => {
       console.log(payload);
       toast(payload.notification.body);
     });
  }, []);
  return (
    <>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />

          <Route path="validation" element={<ValidationPage />} />
          <Route element={<PersistLogin />}>
            <Route path="" element={<Accueil />} />
            <Route path="choose-location" element={<ChooseLocation />} />
            <Route path="choose-duration" element={<ChooseDuration />} />
            <Route path="choose-therapist" element={<ChooseTherapist />} />
            <Route path="choose-service" element={<ChooseService />} />
            <Route path="hotels" element={<Hotel />} />
            <Route path="contact" element={<Contact />} />
            <Route path="gifts" element={<Gift />} />
            <Route path="buy-voucher" element={<Voucher />} />
            <Route path="buy-treatment" element={<Treatment />} />
            <Route path="buy-package" element={<Package />} />
            <Route path="location-pro" element={<LocationPro />} />
            <Route path="fav-flypro" element={<FavFlyPro />} />
            <Route path="flypro-service" element={<FlyProService />} />
          </Route>


          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.Client]} />}>
              <Route path="profile" element={<Profile />} />
              <Route path="commande" element={<Commande />} />
              <Route path="editprofile" element={<EditProfile />} />
              <Route path="editadresse" element={<EditAdresse />} />
              <Route path="payment" element={<Payment />} />
              <Route path="VerificationNumero" element={<VerificationNumero />} />
              <Route path="ValidationNumero" element={<ValidationNumero />} />
              <Route path="confirmation" element={<Confirmation />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
