import React, { useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const Package = () => {

    const navigate = useNavigate();
    const [showAddressField, setShowAddressField] = useState(false);

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
    };

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>Acheter un Package</h2>
                {/* <form onSubmit={handleSubmit}> */}
                <form>
                    <div className="form-group">
                        <label htmlFor="massage">Acheter un Package de </label>
                        <select
                            id="massage"
                            className="form-control"
                        >
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="period">Durée de traitement</label>
                        <select
                            id="period"
                            className="form-control"
                        >
                            <option value="30min">30 Minutes</option>
                            <option value="60min">60 Minutes</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="count">Nombre de traitements</label>
                        <select
                            id="count"
                            className="form-control"
                        >
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="6">6</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="validation">Durée</label>
                        <select
                            id="validation"
                            className="form-control"
                        >
                            <option value="1year">1 an</option>
                        </select>
                    </div>
                    <div className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : ''}}
                        >
                        <div className="text-wrapper"
                        style={{ color: showAddressField ? '#393D44' : ''}}
                        >Physical gift card</div>
                        <label class="switch">
                        <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>

                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="adress">Adresse de livraison</label>
                            <input
                                type="text"
                                id="adress"
                                className="form-control"
                                placeholder='Adresse'
                            />
                        </div>
                    )}

                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>

        </>
    );
}

export default Package;
