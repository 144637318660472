import { endpoints } from '../utils/utils';
import useAuth from './useAuth';
import useAxiosDefault from "./useAxiosDefault";
import { useNavigate } from "react-router-dom";

const useRefreshToken = () => {
    // eslint-disable-next-line no-unused-vars
    const { auth, setAuth } = useAuth();
    const axiosDefault = useAxiosDefault();
    const navigate = useNavigate();
    const refresh = async () => {
        const response = await axiosDefault.get(endpoints.refreshToken, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });
        if (response) {
            setAuth(prev => {
                /* console.log(JSON.stringify(prev));
                console.log(response.data.token); */
                return {
                    ...prev,
                    user: response?.data?.user,
                    status: response?.data?.user?.status,
                    roles: response?.data?.user?.roles,
                    accessToken: response.data.token
                }
            });
            const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');

            if (response?.data?.user?.status === 'PENDING_PHONE_VERIFICATION') {
                navigate("/VerificationNumero", { replace: true });
            } else if (redirectAfterLogin) {
                navigate(redirectAfterLogin, { replace: true });
                sessionStorage.removeItem('redirectAfterLogin');
            }
            return response.data.token;
        }
        return refresh;
    }
    return refresh;
};

export default useRefreshToken;
