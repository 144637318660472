import React from 'react';


const DateTimeSelector = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  modalIsOpen2,
  handleMonthChange,
  handleDayChange,
  handleDayClick,
  handleHourClick,
  daysToShow,
  hours,
  totalPrice,
  scrollLeft,
  scrollRight,
  openModal,
  getMonthName,
  getDayName,
}) => {
  return (
    <div>
      <div className= {`form-group group-mois ${modalIsOpen2 && "group-mois-padding"}`}>
        {!modalIsOpen2 &&
          <label>Mois</label>
        }
        <div className="btn-group" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={() => handleMonthChange(-1)}>
            &lt;
          </button>
          <span className="text-month"> {getMonthName((selectedDate || new Date()).getMonth())}</span>
          <button type="button" className="btn-slide btn-next btn-month" onClick={() => handleMonthChange(1)}>
            &gt;
          </button>
        </div>
      </div>
      <div className="form-group group-jour">
      {!modalIsOpen2 &&
        <label>Jour</label>}
        <div className="btn-group modal-therapist" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={() => handleDayChange(-1)}>
            &lt;
          </button>
          {daysToShow.map((date) => (
            <button
              key={date.getDate()}
              type="button"
              className={`btn-day ${date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                  ? 'disabled'
                  : date?.toDateString() === selectedDate?.toDateString()
                    ? 'selected'
                    : ''
                }`}
              onClick={() => handleDayClick(date)}
            >
              {`${getDayName(date)}`} <br /> <span>{`${date.getDate()}`}</span>
            </button>
          ))}
          <button type="button" className="btn-slide btn-next btn-month" onClick={() => handleDayChange(1)}>
            &gt;
          </button>
        </div>
      </div>
      <div className="form-group group-heure modal-therapist">
      
        <label>{!modalIsOpen2 ? 'Heure' : 'Durée'}</label>
        <div className="btn-group" role="group">
          <button type="button" className="btn-slide btn-prev" onClick={scrollLeft}>
            &lt;
          </button>
          <div className="row-day">
            {hours.map((hour) => (
              <button
                type="button"
                key={hour}
                className={`btn-hour ${selectedTime === hour ? 'selected' : 'btn-outline-primary'}`}
                onClick={() => handleHourClick(hour)}
              >
                {hour} <br/>
                <span>{totalPrice}&nbsp;chf</span>
              </button>
            ))}
          </div>
          <button type="button" className="btn-slide btn-next btn-month" onClick={scrollRight}>
            &gt;
          </button>
        </div>
        {!modalIsOpen2 && window.location.pathname !== "/flypro-booking" &&
          <>
            <a href="#0" className="filter-user" onClick={openModal}>
              Filtrer vos préférences
            </a>
            <p>Veuillez sélectionnez le thérapeute ou cliquez sur le bouton aucune préférence</p>
          </>
        }
      </div>
    </div>
  );
};

export default DateTimeSelector;
