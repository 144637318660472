import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { OverlayView } from '@react-google-maps/api';

const defaultContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 80px)',
};

const defaultOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  draggable: false,
};

const CustomMarker = ({ position, hotel, onHotelClick }) => {
  const markerRef = useRef(null);

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className="map-marker" onClick={(e) => {
        e.stopPropagation();
        onHotelClick(hotel);
      }}>
        <div className="marker-body hotel-item">
          <img className='hotel-image' src={hotel?.media?.length ? hotel.media[0].original_url : 'assets/images/hotel.png'} alt='hotel_image' />
          <div className='hotel-details'>
            <span className='hotel-name'>{hotel?.name}</span>
            <span className='hotel-rating'><img src="/assets/images/icon-stars.svg" alt='star icon' height={13.5} width={13.5} /> 4.9/5</span>
            <span className='badge-duo'>{hotel?.duo_capability ? 'DUO' : 'NO DUO'}</span>
          </div>
        </div>
        <img src='/assets/images/tail.svg' className="marker-tail" />
      </div>
    </OverlayView>
  );
};
export const Map = ({
  hotels = [],
  selectedHotel = null,
  mapOptions = defaultOptions,
  containerStyle = defaultContainerStyle,
  onClick,
  onHotelClick
}) => {
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 46.356, lng: 6.5641 });
  const mapRef = useRef(null);

  useEffect(() => {
    setMarkers(hotels.map((hotel) => {
      return {
        lat: hotel.address.latitude,
        lng: hotel.address.longitude,
        hotel,
      };
    }));
    setCenter({
      lat: hotels[0]?.address.latitude,
      lng: hotels[0]?.address.longitude,
    });
  }, [hotels]);

  useEffect(() => {
    if (selectedHotel) {
      setCenter({
        lat: selectedHotel.address.latitude,
        lng: selectedHotel.address.longitude,
      });
      setZoom(18);
    }
  }, [selectedHotel]);

  const handleMapLoad = (map) => {
    mapRef.current = map;

    if (onClick) {
      map.addListener("click", (event) => {
        // You can pass the event object to the parent or handle it here
        const clickedPosition = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        onClick(clickedPosition); // Trigger the onClick function passed as a prop
      });
    }
  };

  // Dynamically adjust zoom level when the selected hotel changes
  useEffect(() => {
    if (mapRef.current && selectedHotel) {
      mapRef.current.setZoom(18); // Set zoom when hotel is clicked
    }
  }, [selectedHotel]);
  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={containerStyle} // Use the parametrable containerStyle
        center={center}
        zoom={zoom}
        options={mapOptions}
        onLoad={handleMapLoad}
      >
        {markers.map((coordinate, index) => (
          <CustomMarker
            key={index}
            position={{ lat: coordinate.lat, lng: coordinate.lng }}
            hotel={coordinate.hotel}
            onHotelClick={() => onHotelClick(coordinate.hotel)}
          />
        ))}
      </GoogleMap>
    </div>
  );
};
