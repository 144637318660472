import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../api/axios";
import { endpoints } from "../utils/utils";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import AppleLogin from "react-apple-login";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNotifications } from '../context/NotificationProvider';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const notify = useNotifications();
  const emailVerificationStatus = searchParams.get('email_verification_status');

  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [userGoogle, setUserGoogle] = useState([]);

  const appleResponse = (response) => {
    if (!response.error) {
      console.log(jwtDecode(response.authorization.id_token))

      axios
        .post(
          endpoints.appleConnect,
          JSON.stringify({
            code: response.authorization.code,
            id: response.authorization.id_token,
            fcm_token: "aaaa",
            provider: jwtDecode(response.authorization.id_token)
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.status == false) {
            navigate("/register", {
              state: {
                id: response.authorization.id_token,
                fcm_token: "aaaa", email: res.data.email, provider: "apple"
              }
            })
          }
          console.log(jwtDecode(res.data.token));
          const accessToken = res?.data?.token;
          const roles = res?.data?.user?.roles;
          const status = res?.data?.user?.status;
          const refresh = res?.data?.refresh_token;
          const active = true;
          localStorage.setItem("accessToken", res?.data?.token);
          console.log(accessToken);
          setAuth({ user: res?.data?.user, pwd, roles, accessToken, refresh, active, status });
          setUser("");
          setPwd("");
          const redirect = roles.filter((element) => {
            return element === "ROLE_CLIENT";
          });

          if (redirect[0] === "ROLE_CLIENT")
            navigate("/", { replace: true });
          else {
            navigate("/", { replace: true });
            console.log(res);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [faClass, setFaClass] = useState("fa-regular fa-eye");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setFaClass("fa-regular fa-eye-slash");
      return;
    }
    setPasswordType("password");
    setFaClass("fa-regular fa-eye");
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUserGoogle(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*  console.log("params", params.id); */

    try {
      const response = await AuthService.login({ email: user, password: pwd }, axiosPrivate);
      if (response?.user?.status === 'ACTIVE' || response?.user?.status === 'PENDING_PHONE_VERIFICATION') {
        const accessToken = response?.token;
        const roles = response?.user?.roles;
        const status = response?.user?.status;
        const refresh = response?.refresh_token;
        const active = response?.user?.status === 'ACTIVE' || response?.user?.status === 'PENDING_PHONE_VERIFICATION';
        localStorage.setItem("accessToken", response?.token);
        console.log(accessToken);
        setAuth({ user: response?.user, pwd, roles, accessToken, refresh, active, status });
        setUser("");
        setPwd("");
        const redirect = roles.filter((element) => {
          return element === "ROLE_CLIENT";
        });
        const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')
        if (response?.user?.status === 'PENDING_PHONE_VERIFICATION') {
          navigate("/VerificationNumero", { replace: true });
        } else if (redirectAfterLogin) {
          navigate(redirectAfterLogin, { replace: true });
          sessionStorage.removeItem('redirectAfterLogin');
        }
        else {
          setTimeout(() => {
             UserService.update(response?.user?.id,{ fcm_token_web: localStorage.getItem("firebaseToken")}, axiosPrivate  )
          }, 3000);
          
          navigate("/", { replace: true });
          console.log(response);
        }
      } else setErrMsg("Votre compte n'est pas actif");
    } catch (err) {
      //errRef.current.focus();

    }
  };

  useEffect(() => {
    if (emailVerificationStatus === 'success') {
      notify.success('Email Verified Successfully');
    } else if (emailVerificationStatus === 'failed') {
      notify.error('Email Verification Failed');
    }
    localStorage.setItem("persist", persist);
    if (userGoogle) {
      console.log(userGoogle.access_token);
      axios
        .post(
          endpoints.googleConnect,
          JSON.stringify({
            token: userGoogle.access_token,
            fcm_token: "aaaa",
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.status == false) {
            navigate("/register", {
              state: {
                token: userGoogle.access_token,
                fcm_token: "aaaa", email: res.data.email, given_name: res.data.given_name, family_name: res.data.family_name
              }
            })
          }
          console.log(jwtDecode(res.data.token));
          const accessToken = res?.data?.token;
          const roles = res?.data?.user?.roles;
          const status = res?.data?.user?.status;
          const refresh = res?.data?.refresh_token;
          const active = true;
          localStorage.setItem("refreshToken", res?.data?.refresh_token);
          localStorage.setItem("accessToken", res?.data?.token);
          console.log(accessToken);
          setAuth({ user, pwd, roles, accessToken, refresh, active, status });
          setUser("");
          setPwd("");
          const redirect = roles?.filter((element) => {
            return element === "ROLE_CLIENT";
          });

          if (redirect[0] === "ROLE_CLIENT")
            navigate("/", { replace: true });
          else {
            navigate("/", { replace: true });
            console.log(res);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [persist, userGoogle]);

  return (
    <div className="main-login">
      <div className="container">
        <div className="admin-left">
          <div className="logo">Flyspa</div>
          <h2>Se connecter</h2>
          <form name="" id="" onSubmit={handleSubmit}>
            {errMsg ? <p className="error">{errMsg}</p> : ""}
            <div className="form-text">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Name@mail.com"
                className="email"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
            </div>
            <div className="form-text">
              <input
                type={passwordType}
                onChange={(e) => setPwd(e.target.value)}
                name="password"
                id="password"
                value={pwd}
                placeholder="Password"
                className="password"
                required
              />
              <div onClick={togglePassword} className="eye">
                <a
                  href="#0"
                  onClick={togglePassword}
                  role="button"
                  tabIndex="0"
                >
                  <i onClick={togglePassword} className={faClass}></i>
                </a>
              </div>
            </div>
            <a href="#0" className="pass-oublie">Mot de passe oublié ?</a>
            <div className="form-submit">
              <input type="submit" name="sign" id="sign" value="Connexion" />
            </div>
          </form>
          <div className="form-login">
            <div className="text-ou">ou</div>
            <div className="sign-apple-google">
              <AppleLogin
                clientId="digital.pulse.flyspaweb"
                redirectURI="https://flyspa-web.dvpt.pulsweb.ch"
                usePopup={true}
                callback={appleResponse} // Catch the response
                scope="email name"
                responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button className="login-with-apple-btn"
                    onClick={renderProps.onClick}
                  >
                    Se connecter avec Apple id
                  </button>
                )}
              />

              <button className="login-with-google-btn" onClick={() => login()}>
                Se connecter avec compte Gmail{" "}
              </button>
            </div>
            <div className="inscription">
              Vous n’avez pas encore de compte ?<br />
              <Link to="/register">S’inscrire</Link>
            </div>
            <a href="#" className="passer-connexion">Passer la connexion</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
