import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { endpoints } from '../utils/utils';

const MenuHeader = () => {
  const [selected, setSelected] = useState('home');
  const [user, setUser] = useState();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [burgerMenuVisible, setBurgerMenuVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuVisible(!burgerMenuVisible);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getProfil);
        isMounted && setUser(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (!auth || Object.keys(auth).length === 0) {
      return;
    }
    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleSelectionChange = (event) => {
    setSelected(event.target.id);
    setBurgerMenuVisible(false);
  };

  return (
    <div className="menu-header">
      <Link to='/'><img src='assets/images/logo.svg' alt='FlySpa Logo' className='logo' /></Link>
      <div className="menu">
        <div className={`menu-left ${burgerMenuVisible ? 'visible' : ''}`}>
          <ul className="item-list">
            <li className={selected === 'home' ? 'active' : ''}>
              <Link to="/" id="home" onClick={handleSelectionChange}>Acceuil</Link>
            </li>
            <li className={selected === 'services' ? 'active' : ''}>
              <div className="dropdown">
                <div className="dropdown-toggle" onClick={toggleDropdown}>
                  Services<img src="assets/images/arrow_down.svg" alt="icon" />
                </div>
                {dropdownVisible && (
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/choose-service">Choisissez votre soin</Link>
                    </li>
                    <li>
                      <Link to="/hotels">Choisissez votre hotel partenaire</Link>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li className={selected === 'flypro' ? 'active' : ''}>
              <Link to="/flypro" id="flypro" onClick={handleSelectionChange}>FlyPro</Link>
            </li>
            {user && (
              <li className={selected === 'commande' ? 'active' : ''}>
                <Link to="/commande" id="commande" onClick={handleSelectionChange}>Commandes</Link>
              </li>
            )}
            <li className={selected === 'contact' ? 'active' : ''}>
              <Link to="/contact" id="contact" onClick={handleSelectionChange}>Contact</Link>
            </li>
            <li className={selected === 'help' ? 'active' : ''}>
              <Link to="/help" id="help" onClick={handleSelectionChange}>Besoin d'aide ?</Link>
            </li>
          </ul>
        </div>
        {user && (
          <div className="menu-right">
            <ul>
              <li><a href="#" className="notif"><span>3</span></a></li>
              <li><a href="/Profile" className="account">{user?.user?.extra?.initials}</a></li>
              <li><a href="#" className="burger" onClick={toggleBurgerMenu}></a></li>
            </ul>
          </div>
        )}
      </div>
      {burgerMenuVisible && (
        <div className="burger-menu-container">
          <div className="burger-menu">
            <ul className="item-list">
              <li className={selected === 'home' ? 'active' : ''}>
                <Link to="/" id="home" onClick={handleSelectionChange}>Acceuil</Link>
              </li>
              <li className={selected === 'services' ? 'active' : ''}>
                <div className="dropdown">
                  <div className="dropdown-toggle" onClick={toggleDropdown}>
                    Services<img src="assets/images/arrow_down.svg" alt="icon" />
                  </div>
                  {dropdownVisible && (
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/choose-service">Choisissez votre soin</Link>
                      </li>
                      <li>
                        <Link to="/hotels">Choisissez votre hotel partenaire</Link>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              <li className={selected === 'flypro' ? 'active' : ''}>
                <Link to="/flypro" id="flypro" onClick={handleSelectionChange}>FlyPro</Link>
              </li>
              {user && (
                <li className={selected === 'commande' ? 'active' : ''}>
                  <Link to="/commande" id="commande" onClick={handleSelectionChange}>Commandes</Link>
                </li>
              )}
              <li className={selected === 'contact' ? 'active' : ''}>
                <Link to="/contact" id="contact" onClick={handleSelectionChange}>Contact</Link>
              </li>
              <li className={selected === 'help' ? 'active' : ''}>
                <Link to="/help" id="help" onClick={handleSelectionChange}>Besoin d'aide ?</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuHeader;
