import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import coins from '../assets/images/coins.svg';
import ticketGift from '../assets/images/ticketGift.svg';
import ticketPack from '../assets/images/ticketPack.svg';

const Gift = () => {

  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="container gift-options-container">
      <h2>Catégorie des cadeaux</h2>
        <div className="gift-option">
          <div className="icon">
            <img src={coins} alt="Icon" />
          </div>
          <div className="content">
            <h3>Acheter un bon</h3>
            <a href="/buy-voucher">More info &rsaquo;</a>
          </div>
        </div>
        <div className="gift-option">
          <div className="icon">
            <img src={ticketGift} alt="Icon" />
          </div>
          <div className="content">
            <h3>Acheter un traitement</h3>
            <a href="/buy-treatment">More info &rsaquo;</a>
          </div>
        </div>
        <div className="gift-option">
          <div className="icon">
            <img src={ticketPack} alt="Icon" />
          </div>
          <div className="content">
            <h3>Acheter un Package</h3>
            <a href="/buy-package">More info &rsaquo;</a>
          </div>
        </div>
      </div>

    </>
  );
}

export default Gift;
