import React, { useEffect, useState } from 'react'


function HotelList({ hotels, onHotelShowMapClick, onHotelFilter, onHotelClick }) {
  const [hotelList, setHotelList] = useState(hotels);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setHotelList(hotels);
  }, [hotels])

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    onHotelFilter(e.target.value);
  }

  return (
    <>
      <div className='hotel-list'>
        <div className='hotel-list-header'>
          <h3>Les hotels partenaires</h3>
          <div className='search'>
            <input type='text' placeholder='Recherche' value={search} onChange={handleSearchChange} />
            <img src='/assets/images/icon-search.svg' alt='search' />
          </div>
        </div>
        {hotelList.length > 0 && (
          <ul className='hotel-items'>
            {hotelList.map((hotel) => (<li className='hotel-item' key={hotel.id} onClick={() => onHotelClick(hotel)}>
              <img className='hotel-image' src={hotel.media?.length ? hotel.media[0].original_url : 'assets/images/hotel.png'} alt='hotel_image' />
              <div className='hotel-details'>
                <span className='badge-duo'>{hotel.duo_capability ? 'DUO' : 'NO DUO'}</span>
                <span className='hotel-name'>{hotel.name}</span>
                <span className='hotel-rating'><img src="/assets/images/icon-stars.svg" alt='star icon' height={13.5} width={13.5} /> 4.9/5</span>
                <span className='hotel-distance'>2km from the city center</span>
                <button className='show-map' onClick={(e) => { e.stopPropagation(); onHotelShowMapClick(hotel) }}><img src='/assets/images/icon-map.svg' />Show on map</button>
              </div>
            </li>
            ))}
          </ul>
        )}
      </div >
    </>
  );
}

export default HotelList;
