import React from 'react';

const Informations = () => {
  return (
    <div className="modal-therapist">
      <h3 className='info-title'>Informations</h3>
      <div className="informations">

        <h4>Bio</h4>
        <p>Sapien amet tincidunt venenatis blandit...</p>

      </div>
      <div className="informations">

        <h4>Soins</h4>
        <ul className='list-soin'>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
        </ul>

      </div>
      <div className="informations">

        <h4>Expérience</h4>
        <ul className='list-soin'>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
          <li>Soins de peau</li>
        </ul>

      </div>
      <div className="informations">

        <h4>Langue</h4>
        <p>Anglais, français</p>

      </div>
    </div>
  );
};

export default Informations;
