// firebase.js
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken } from '@firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDyxcYv7g5iPgyrGO_lFAw-k1aB6Du4WA0",
    authDomain: "flypro-426311.firebaseapp.com",
    projectId: "flypro-426311",
    storageBucket: "flypro-426311.firebasestorage.app",
    messagingSenderId: "472957532659",
    appId: "1:472957532659:web:5f7a68bc0adf64d457fa86",
    measurementId: "G-ZZC3RT2CC3"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);
export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);

    if (permission === "granted") {
        const token = await getToken(messaging, {
            vapidKey:
                "BOxZXbVLbvg5FVicQ8JuNIFauAzb6liRXZXzok32sgUp_kOsclpFb1oJIZmtcW-I6mTfE4RJbyxQqAYUk8aa-JA",
        });
        console.log(token);
        if (token) {
            console.log(token);
            localStorage.setItem('firebaseToken', token); 
        }
    }
};