// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import UserService from '../services/user.service';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const VerificationNumero = () => {
  const [formData, setFormData] = useState({
    otp: ''
  });
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  useEffect(() => {
    sendOTP();
  }, []);

  const sendOTP = async () => {
    try {
      await UserService.sendOTP(axiosPrivate);
      disableResend();
    } catch (e) {

    }

  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await UserService.verifyOTP(formData, axiosPrivate);
      navigate('/');
    } catch (e) {

    }
  }

  const handleChange = (event) => {

    setFormData({ otp: event.target.value })

  }
  const disableResend = (time = 10000) => {
    const resendBtn = document.querySelector('#resend');

    resendBtn.disabled = true;
    resendBtn.innerHTML = `Resend (${time / 1000}s)`;
    if (time === 0) {
      resendBtn.disabled = false;
      resendBtn.innerHTML = `Resend`;
    }
    else {
      setTimeout(() => {
        disableResend(time - 1000);
      }, 1000);
    }
  }
  return (
    <>
      <Header />

      <div className="container container-contact">
        <h2>Vérification du numéro de téléphone</h2>
        <p>Afin de confirmer votre numéro de mobile un code vous à été envoyé.<br />Veuillez saisir le code reçu par SMS</p>
        <form onSubmit={handleSubmit}>
          <div className="flex-row gap-10 align-center">
            <div className="form-text flex-1-auto no-margin">
              <label>Code</label>
              <input type="text" value={formData.otp} onChange={handleChange} name="otp" id="otp" />
            </div>
            <button type='button' onClick={sendOTP} className="btn no-margin" id='resend'>Resend</button>
          </div>
          <div className="form-submit">
            <input type="submit" value="Vérifier" />
          </div>
        </form>
      </div>
    </>
  );
}

export default VerificationNumero;
