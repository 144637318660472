import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Nav from './Nav';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { endpoints } from '../utils/utils';
import UserService from '../services/user.service';

const EditProfile = () => {
  const [user, setUser] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    civility: "",
    birthdate: "",
    email: "",
    password: "",
    id:""
  });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getProfil);
        if (isMounted) {
          setUser(response.data);
          setFormData({
            id: response.data.user.id || "",
            firstname: response.data.user.firstname || "",
            lastname: response.data.user.lastname || "",
            phone: response.data.user.phone || "",
            birthdate: response.data.user.birthdate || "",
            email: response.data.user.email || "",
            civility: response.data.user.civility || "",
            password: "" // Keeping it empty for security reasons
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //alert("")
    try {
      const response = await UserService.update(formData.id, formData, axiosPrivate);
      console.log('Profile updated successfully:', response.data);
      // Optionally navigate to another page or show a success message
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  return (
    <>
      <Header />
      <div className="container container-profile">
        <div className="name">Modifier mon profil</div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="sexe">
            <div className="choix">
              <input
                type="radio"
                name="civility"
                value="madame"
                checked={formData.civility === 'MS'}
                onChange={handleChange}
              />
              <label htmlFor="madame">Madame</label>
            </div>
            <div className="choix">
              <input
                type="radio"
                name="civility"
                value="monsieur"
                checked={formData.civility === 'MR'}
                onChange={handleChange}
              />
              <label htmlFor="monsieur">Monsieur</label>
            </div>
          </div>
          <div className="form-group">
            <label>Prénom</label>
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              placeholder="John"
            />
          </div>
          <div className="form-group">
            <label>Nom</label>
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              placeholder="Appleseed"
            />
          </div>
          <div className="form-group">
            <label>Numéro de téléphone portable</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="+41 123 4555 6334"
            />
          </div>
          <div className="form-group">
            <label>Adresse email</label>
            <input
              type="email"
              disabled
              value={formData.email}
              onChange={handleChange}
              placeholder="john.appleseed@gmail.com"
            />
          </div>
          <div className="form-group">
            <label>Nouveau mot de passe</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="password"
            />
          </div>
          <div className="form-group">
            <label>Date de naissance</label>
            <input
              type="date"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleChange}
              placeholder="01/01/1971"
            />
          </div>
          <div className="form-check">
            <input type="checkbox" name="cgu" id="cgu" />
            <label htmlFor="cgu">Accepter les CGU et la Politique de Confidentialité *</label>
          </div>
          <div className="form-submit">
            <input type="submit" value="Enregistrer" />
          </div>
        </form>
        <a href="#" className="btn-remove-user">Supprimer le compte</a>
      </div>
    </>
  );
}

export default EditProfile;
