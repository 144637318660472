import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuHeader from './MenuHeader'
import Nav from './Nav';
import { useLocation } from 'react-router-dom';

function ChooseDuration() {
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [duration, setDuration] = useState('60'); // Default to 60 minutes
  const [price, setPrice] = useState(0);
  const location = useLocation();
  const { address } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate the price based on number of people and duration
    const basePrice = 50; // Example base price per person per hour
    setPrice(basePrice * numberOfPeople * (duration / 60));
  }, [numberOfPeople, duration]);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/choose-therapist', { state: { address, numberOfPeople, duration } });
  };

  return (
    <>
      <MenuHeader />
      <Nav />
      <div className="container container-location">
        <h2>Choisissez la durée du soin</h2>
        <form className="form-duree" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre de personnes</label>
            <div className="btn-group" role="group">
              {[1, 2, 3, 4].map((num) => (
                <button
                  type="button"
                  key={num}
                  className={`btn-default ${numberOfPeople === num ? 'btn-checked' : ''}`}
                  onClick={() => setNumberOfPeople(num)}
                >
                  {num}
                </button>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label>Durée du soin</label>
            <div className="btn-group group-3" role="group">
              {['60', '90', '120'].map((time) => (
                <button
                  type="button"
                  key={time}
                  className={`btn-default ${duration === time ? 'btn-checked' : ''}`}
                  onClick={() => setDuration(time)}
                >
                  {time} min
                </button>
              ))}
            </div>
          </div>
          <div className="recap">
            <p>Un Fly Pro pour {numberOfPeople} personne(s)</p>
            <h4>Total</h4>
            <p className="time-prix">{duration} min. <span>|</span> {price} chf</p>
          </div>
          <button type="submit" className="btn btn-primary">
            Suivant
          </button>
        </form>
      </div>
    </>
  );
}

export default ChooseDuration;
