// Home.js
import React, { useState, useEffect } from "react";
import MenuHeader from "./MenuHeader";
import BookingService from "../services/booking.service";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";


function Commande() {
  const [bookings, setBookings] = useState([]);
  const axionPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const fetchBookings = async () => {
    try {
      const response = await BookingService.index({ with: 'therapists.media,client,product.category', client_id: `${auth?.user ? auth.user.id : -1}`, sort: '-created_at' }, axionPrivate);
      console.log({ response })
      setBookings(response)
    } catch (error) {

    }
  }
  const formatSelectedDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(date));
  };
  useEffect(() => {
    fetchBookings();
  }, [])
  return (
    <>
      <MenuHeader />
      <section className="commande-history">
        <div className="commande-list">
          {bookings.map(booking => {
            return booking.therapists.map(therapist => (
              <div className={`commande-card ${booking.status === 'PENDING_ACKNOWLEDGEMENT' ? 'card-disabled' : ''} `}>
                <div className="product-details">
                  <span>{booking?.product?.category?.title}</span>
                  <span>{formatSelectedDate(booking?.start_before)} - {booking?.start_before.split('T')[1].split(':')[0]}:{(booking?.start_before.split('T')[1])?.split(':')[1]}</span>
                </div>
                <div className="therapist-details">
                  <img src={!therapist?.media.length ? "assets/images/icon-pro.svg" : therapist?.media[0].original_url} alt="logo therapist"></img>
                  <span>{therapist?.firstname}</span>
                </div>
              </div>
            ))
          })}

        </div>
      </section>
    </>
  );
}

export default Commande;
