import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const Voucher = () => {

    const navigate = useNavigate();
    const [showAddressField, setShowAddressField] = useState(false);

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
    };

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>Acheter un bon</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="massage">Acheter un bon de </label>
                        <select
                            id="massage"
                            className="form-control"
                        >
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalCode">Code postal</label>
                        <input
                            type="text"
                            id="postalCode"
                            className="form-control"
                            placeholder='Code postal'
                        />
                    </div>
                    <div className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : ''}}
                        >
                        <div className="text-wrapper"
                        style={{ color: showAddressField ? '#393D44' : ''}}
                        >Physical gift card</div>
                        <label class="switch">
                        <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>

                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="adress">Adresse de livraison</label>
                            <input
                                type="text"
                                id="adress"
                                className="form-control"
                                placeholder='Adresse'
                            />
                        </div>
                    )}

                    
                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>

        </>
    );
}

export default Voucher;
