import React, { useState } from 'react';
import MenuHeader from './MenuHeader';
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
const libraries = ['places'];

const LocationPro = () => {
  const navigate = useNavigate();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
    libraries,
  });
  const [address, setAddress] = useState({
    street: '',
    city: '',
    zip_code: '',
    street_number: '',
    state: '',
    state_code: '',
    country: '',
    country_code: '',
    lat: null,
    lng: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (address) {
      const adrs = {
        address: address,
      };
      sessionStorage.setItem('address', JSON.stringify(adrs));
      navigate('/fav-flypro', { state: { adress: address } });
      console.log("session loc", adrs);
    }
  };
  
  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: '',
      country_code: '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          newAddress.state = component.long_name;
          newAddress.state_code = component.short_name;
          break;
        case 'country':
          newAddress.country = component.long_name;
          newAddress.country_code = component.short_name;
          break;
        case 'street_number':
          newAddress.street_number = component.long_name;
          break;
        default:
          break;
      }
    }

    setAddress(newAddress);
  };
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <>
      <MenuHeader />
      <div className="container">
        <div className="container-location">
          <h2>Où souhaitez-vous le soin ?</h2>

          <div className="form-group">
            <label htmlFor="street">Saisissez une adresse</label>
            <Autocomplete
              apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
              onPlaceSelected={handlePlaceSelect}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'CH' },
              }}
              style={{
                width: '100%',
                padding: '0 15px',
                height: '60px',
                border: '0',
                borderRadius: '20px',
                boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)',
              }}
            />
          </div>
        </div>
        {address.lat && address.lng && (
          <form onSubmit={handleSubmit}>
            <div className="container-location">
              <div className="form-group">
                <label htmlFor="street">Rue</label>
                <input
                  type="text"
                  id="street"
                  className="form-control"
                  value={address.street}
                  onChange={(e) => setAddress({ ...address, street: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">Canton</label>
                <input
                  type="text"
                  id="state"
                  className="form-control"
                  value={address.state}
                  onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="postalCode">Code postal</label>
                <input
                  type="text"
                  id="postalCode"
                  className="form-control"
                  value={address.zip_code}
                  onChange={(e) => setAddress({ ...address, zip_code: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">Ville</label>
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  value={address.city}
                  onChange={(e) => setAddress({ ...address, city: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className="btn-bottom">
              <button type="submit" className="btn btn-primary">
                Suivant
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default LocationPro;
